import React from "react"
import PropTypes from "prop-types"

import { RecoilRoot, setState } from 'recoil'

import NavbarTwoCustom from './_App/NavbarTwoCustom';  //   <NavbarTwoCustom />
//import { ToastProvider } from 'react-toast-notifications';
import Web3 from 'web3';
import axios from 'axios';

import Footer from './_App/Footer';
import FooterMin from './_App/FooterMin';
import GoTop from './_App/GoTop';  //   <NavbarTwoCustom />

// <PreSaleStatsDone status=this.state.status eth_value=this.state.eth_value eth_address=this.props.eth_address />
function PreSaleStatsDone(props) {

    return (
        <React.Fragment>




        {(""+props.status).toLowerCase() == "done" ?


        <React.Fragment>
            <section className="what-we-do-area  pt-3 pb-3" style={{ "backgroundColor": "#282d41" }}>
                <div className="container ">

                    <div className="row justify-content-center">
                        <div className="col-10" >
                            <div className=" d-flex flex-column pt-2 pb-2 justify-content-center">
                                <p style={{ "color": "white" }} className="text-center">

                                    THE NFTs are with you now.
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>

                :


                (props.whitelisted === "false") ?

                <React.Fragment>
                    <section className="what-we-do-area  pt-3 pb-3" style={{ "backgroundColor": "#282d41" }}>
                        <div className="container ">

                            <div className="row justify-content-center">
                                <div className="col-10" >
                                    <div className=" d-flex flex-column pt-2 pb-2 justify-content-center">
                                        <p style={{ "color": "white" }} className="text-center">

                                            Your address has not yet been selected to be part of the whitelist - This may be because the raffle has not taken place yet. Please check back again in an hour or when you see an annoucement in Discord. We are adding Whitelisted new addresses every day. When your address gets selected, you will see the instructions appear here or by clicking on "Whitelist Instructions" in the top menu bar.

                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                </React.Fragment>


                :

                <React.Fragment>

                    <section className="what-we-do-area  pt-3 pb-3" style={{ "backgroundColor": "#282d41" }}>
                        <div className="container ">

                            <div className="row justify-content-center">
                                <div className="col-10" >
                                    <div className=" d-flex flex-column pt-2 pb-2 justify-content-center">
                                        <p style={{ "color": "white" }} className="text-center">
                                            If you have not already done so, you can go to metamask and send (in the next 30 minutes) <span style={{ "color": "#FF13A7", "fontWeight": "bold", "fontSize": "18px" }}>{props.eth_value.toFixed(5)} ETH</span> in TOTAL <br />
                                                to the following address:  <br /><a className="custom" href={"https://etherscan.io/address/" + props.eth_address} target="_blank" style={{ "fontSize": "18px" }}  >{props.eth_address}</a>
                                            <br />

                                            </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="what-we-do-area bg-fafafb pt-3 pb-100" >
                        <div className="container ">

                            <div className="row justify-content-center">
                                <div className="col-10">
                                    <div className=" pt-2 pb-2 justify-content-center">
                                        <p className="text-center">
                                            We will mint you <span style={{ "color": "black", "fontWeight": "bold", "fontSize": "18px" }}>{props.token_amount} NFTs</span> manually directly <br />to your wallet at the<span style={{ "color": "black", "fontWeight": "bold", "fontSize": "18px" }}> REGISTERED ADDRESS </span> in the next 24 hours after receiving your payment.

                        They will then show up in <a href="/collection" style={{ "fontSize": "18px" }}>"My Collection"</a> and on <a href="https://opensea.io/collection/niftyriots" style={{ "fontSize": "18px" }}>OpenSea</a>. If you are facing any issues, please contact <span style={{ "color": "black", "fontWeight": "bold", "fontSize": "18px" }}> GloriaMundi#7333 </span> ONLY in the NiftyRiots Discord server.
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>

                </React.Fragment>


        }







        </React.Fragment>

    );
}

function PreSalesStatusIsEmpty(props){
    return(
        <React.Fragment>
        <section className="what-we-do-area bg-fafafb pt-100 pb-2">

            <div className="container ">

                <div className="row justify-content-center">
                    <div className="col-10">
                        <div className=" pb-2 justify-content-center">

                            <p className="text-center">   You do not have an entry in the presale yet.<br /></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="what-we-do-area  pt-3 pb-3" style={{ "backgroundColor": "#282d41" }}>
            <div className="container ">

                <div className="row justify-content-center">
                    <div className="col-10" >
                        <div className=" d-flex flex-column pt-2 pb-2 justify-content-center">
                            <p style={{ "color": "white" }} className="text-center">

                                with this wallet address:  <br /><a className="custom" href={"https://etherscan.io/address/" + props.account} target="_blank" style={{ "fontSize": "18px" }}  >{props.account}</a>
                                <br />
                            </p>
                        </div>

                    </div>
                </div>
            </div>
        </section>

    </React.Fragment>
    );

}

function PreSalesStatusNoAccount(props){

    return(
        <React.Fragment>
        <section className="what-we-do-area bg-fafafb pt-100 pb-2">

            <div className="container ">

                <div className="row justify-content-center">
                    <div className="col-10">
                        <div className=" pb-2 justify-content-center">

                        </div>
                    </div>
                </div>
            </div>
        </section>

        
        <section className="what-we-do-area  pt-3 pb-3" style={{ "backgroundColor": "#282d41" }}>
            <div className="container ">

                <div className="row justify-content-center">
                    <div className="col-10" >
                        <div className=" d-flex flex-column pt-2 pb-2 justify-content-center">
                            <p style={{ "color": "white" }} className="text-center">   You do not have an entry in the presale yet.<br /></p>
                        </div>

                    </div>
                </div>
            </div>
        </section>

    </React.Fragment>
    );

}



class PreSaleInsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            account: '',
            eth_value: 0,
            token_amount: 0,
            IsEmpty: "true",
            status: "",
            whitelisted: ""
        };

    }


    //mounting the main Element
    async componentDidMount() {
        await this.loadWeb3(this.props.dispatch)

    }


    //network and web3 logic

    async loadWeb3(dispatch) {
        if (window.ethereum) {

            console.log("Starting up Collectopm....")
            const web3 = new Web3(window.ethereum)    // METAMASK CONNECTION
            const netId = await web3.eth.net.getId()     // gets the value of the network
            const enabledWeb3 = await ethereum.enable();
            const accounts = await web3.eth.getAccounts()

            //load balance
            if (typeof accounts[0] !== 'undefined') {
                //console.log("accounts")
                //console.log(accounts[0])
                //this.setState({ account: accounts[0] })
                axios.get("presale_ins/" + accounts[0], {
                    headers: {
                        'Accept': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Headers': 'Content-Type'

                    }
                }).then((response) => {

                    this.setState({
                        account: accounts[0],
                        eth_value: response.data["ethvalue"],
                        token_amount: response.data["tokenamount"],
                        IsEmpty: response.data["isEmpty"],
                        status: response.data["status"],
                        whitelisted: response.data["whitelisted"],

                    });

                    console.log("response.data",response.data);
                    console.log("props.whitelisted....",this.state.whitelisted);
                    console.log("props.isEmpty....",this.state.isEmpty);


                });



            } else {
                //window.alert('Please login with MetaMask')
                this.props.addToast('Please connect the metamask', { appearance: 'info' });
                return
            }


        } else {
            this.props.addToast('Please install MetaMask', { appearance: 'info' });
        }
    }


    render() {


        return (
            <React.Fragment>
                <RecoilRoot>
                    <NavbarTwoCustom />

                    {this.state.account != '' ?

                        this.state.IsEmpty === "false" ?

                            <React.Fragment>
                                <section className="what-we-do-area bg-fafafb pt-100 pb-2">

                                    <div className="container ">

                                        <div className="row justify-content-center">
                                            <div className="col-10">
                                                <div className=" pb-2 justify-content-center">

                                                    <p className="text-center">   Your whitelist request has been registered.<br />
                                                    STATUS :  <span style={{ "color": "#FF13A7", "fontWeight": "bold", "fontSize": "18px" }}> {this.state.status == "" ? "Pending" : this.state.status}</span> <br />
                                                     </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <PreSaleStatsDone status={this.state.status} eth_value={this.state.eth_value} eth_address={this.props.eth_address} token_amount={this.state.token_amount}  whitelisted={this.state.whitelisted}/>
                            </React.Fragment>
                            :
                            <PreSalesStatusIsEmpty account={this.state.account} />
                    :

                        <PreSalesStatusNoAccount />
                    }
                    <GoTop />
                </RecoilRoot>
                <FooterMin fixedBottom={true} S3_ADDRESS={this.props.S3_ADDRESS} user_signed_in={this.props.user_signed_in} />
            </React.Fragment>)
    }
}

PreSaleInsPage.propTypes = {
    S3_ADDRESS: PropTypes.string
};

export default PreSaleInsPage
