import React from 'react';
import PropTypes from 'prop-types'

const FooterMin = (props) => {

    const currentYear = new Date().getFullYear();
    let classnamefooter = (props.fixedBottom)?  "container fixed-bottom" : "container";

    return (
        <footer className="footer-area-min bg-color footer-custom">
            <div className={ classnamefooter }>
                <div className="footer-bottom-area-min" >
                    <div className="row align-items-center font-Nunito">
                        <div className="col-lg-6 col-md-6">
                            <p>Copyright &copy;{currentYear} <strong>NiftyRiots </strong> All rights reserved</p>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <ul>

                                <li>

                                    <a href="/terms_of_service" className="font-Nunito">Terms & Conditions</a>
                                                               
                                </li>
                                <li>

                                    <a href="/disclaimer" className="font-Nunito">Disclaimer</a>
                                
                                </li>
                                <li>
       
                                    <a href="/how_to_buy" className="font-Nunito"  >How to Buy</a>
                                

                                </li>
                                <li>

                                    <a href="/users/sign_in" >Admin</a>

                                </li>
                                                                

                            </ul>
                        </div>

                    </div>
                </div>
            </div>

        </footer>
    );
}

FooterMin.propTypes = {
    fixedBottom: PropTypes.bool
  }

export default FooterMin;